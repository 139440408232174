// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.35s,
		transition:			0.2s,
		banner:				2.5s
	);

// Size.
	$size: (
		border-radius:		4px,
		element-height:		2.75em,
		element-margin:		2em,
		inner:				65em
	);

// Font.
	$font: (
		family:				('Open Sans', Helvetica, sans-serif),
		family-fixed:		('Open Sans', monospace),
		weight:				300,
		weight-bold:		600,
		letter-spacing:		0.025em,
		letter-spacing-alt:	0.25em
	);

// Palette.
	$palette: (
		bg:					#cdad00,
		bg-alt:				#486257,
		fg:					#ffffff,
		fg-bold:			#ffffff,
		fg-light:			#ffffff,
		//fg-light:			rgba(244,244,255,0.2),
		border:				rgba(212,212,255,0.1),
		border-bg:			rgba(212,212,255,0.035),
		highlight:			#e3c025,
		accent1:			#9b964b,
		accent2:			#345145,
		accent3:			#eeee00,
		accent4:			#e7b788,
		accent5:			#8ea9e8,
		accent6:			#87c5a4
	);
